import React from "react";
import {
    ProbTableProps,
} from "./interfaces";
import {
    StyledDiceTable,
    StyledProbabilityCell,
} from "./style"

export function ProbabilityTable({probabilities, toTheAIstring}: ProbTableProps) {
    // Generate an array of all possible outcomes from 21 to 36
    // const allOutcomes = Array.from({ length: 16 }, (_, i) => i + 21);
    let maxProbability = 100;

    const allTileValues = Array.from({length: 16}, (_, i) => i + 21);
    const allColumns = Array.from({length: 6}, (_, i) => i);


    for (const column of allColumns) {
        // Iterate over each possible tile value
        for (const tileValue of allTileValues) {
            // Check if the current column and tile value exist in probabilities
            if (probabilities[column] && probabilities[column][tileValue]) {
                const probability = probabilities[column][tileValue];
                // Update maxProbability if the current probability is greater
                if (probability > maxProbability) {
                    maxProbability = probability;
                }
            }
        }
    }

    return (
        <>
            <h3>
                <span className={'tooltip'}>
                    Appeal
                    <span className={'tooltip-text'}>
                        Appeal = Probability × <br/> ( Value × 2 if Stolen, 1 otherwise + Potential Loss )</span>
                </span> {toTheAIstring ? toTheAIstring : "to the AI"}
            </h3>
            <StyledDiceTable>

                <tbody>
                {/* Iterate over the rows */}
                {allTileValues.map((tileValue) => (
                    <tr key={tileValue}>
                        <td className={'head'}>{tileValue}</td>
                        {/* Iterate over the columns (0 to 5) */}
                        {allColumns.map((column) => (
                            probabilities[column] ? (
                                <StyledProbabilityCell key={column}
                                    // @ts-ignore
                                                       probability={probabilities[column][tileValue]}
                                                       maxProba={maxProbability}>
                                    {/* Display probability as percentage rounded to integer, otherwise leave cell empty */}
                                    {probabilities[column][tileValue] && `${(probabilities[column][tileValue]).toFixed(0)}`}
                                </StyledProbabilityCell>
                            ) : (
                                <td key={column}></td>
                            )
                        ))}
                    </tr>
                ))}
                </tbody>
            </StyledDiceTable></>
    );
}

