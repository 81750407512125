import {
    dice,
    Tile,
    Player, tileTable, allTiles,
} from "./objects";

import {
    MaxProbabilityResult,
    ProbabilityRecord,
    ProbabilityRecords,
    ProbabilityCalculation,
    ProbabilityCalculations,
} from "./interfaces";

import {
    players,
    transferRecord,
} from "./objects";

export function boxMullerTransform() {
    const u1 = Math.random();
    const u2 = Math.random();
    return Math.sqrt(-2.0 * Math.log(u1)) * Math.cos(2.0 * Math.PI * u2);
}

export function getNormallyDistributedRandomNumber(mean: number, stddev: number) {
    return boxMullerTransform() * stddev + mean;
}

export const findMaxProbability = (probabilities: ProbabilityCalculations): MaxProbabilityResult => {
    let maxRow = undefined;
    let maxCol = undefined;
    let maxValue = -Infinity;

    for (const col in probabilities) {
        for (const row in probabilities[col]) {
            const value = probabilities[col][row];
            // We assume that keys are sorted such that for equal values
            // the larger row remains (e.g., for 21: 100%, 22: 100%).
            if (value >= maxValue) {
                maxValue = value;
                maxRow = Number(row);
                maxCol = Number(col);
            }
        }
    }
    return {targetTileValue: maxRow, targetDieIndex: maxCol};
}

export const getRandomFace = (limit?: number) => {
    let emojisSubset = ["🧑", "👩", "👱‍♀️", "👨", "👸", "👶", "🤴", "👵", "👴", "🐵"];
    if (limit && limit <= emojisSubset.length) {
        emojisSubset = emojisSubset.slice(0, limit);
    }
    const randomIndex = Math.floor(Math.random() * emojisSubset.length);
    return emojisSubset[randomIndex];
};


export const countActivePlayers = (players?: Player[]): number => {
    if (players) {
        return players.filter(player => player.is_active).length;
    } else {
        return 0;
    }
};

export const changePlayer = (do_add: boolean = true): Array<Player> => {
    let activityIndex;
    if (do_add) {
        // Find first index of first inactive player
        activityIndex = players.findIndex(player => !player.is_active);
    } else {
        // Find the last index of the active players
        const activePlayers = players.filter(player => player.is_active);
        activityIndex = activePlayers.length - 1;
    }
    players[activityIndex].toggleActive();
    return players;
};

export const pickTile = (from: Player, to: Player, tile: Tile): boolean => {
    // For the table we need the tile value, but know the player.
    const picked_tile = from.removeFromCollection(tile)
    transferRecord.set(from, to, tile)
    if (picked_tile !== undefined) {
        to.addToCollection(picked_tile)
        return true
    }
    return false
}

export const stealTile = (from: Player, to: Player, loss: boolean = true): boolean => {
    //  We don't pop immediately
    const tile = from.lastTile();
    if (tile && (loss || dice.score() === tile.value)) {
        transferRecord.set(from, to, tile)
        to.addToCollection(from.popFromCollection());
        return true
    }
    return false
}

export const calculateProbabilities = (
    currentCollection: number[],
    rolledDice: number[],
    currentPlayer: Player,
    minimalData: ProbabilityRecords,
    exactlyData: ProbabilityRecords): ProbabilityCalculations => {
    const getMultiplier = (tileValue: number): number => {
        // Get an array of other players
        const competitors = players.filter(player => player !== currentPlayer);

        // Check if tileValue exists in tileTable (assuming tileTable is defined)
        if (tileTable.getTileIndex(tileValue) !== undefined) {
            return 1;
        }

        // If the player has the highest score, and there is one tile left, there is no additional benefit of stealing
        const hasHighestScore = players.filter(player => player !== currentPlayer).every(otherPlayer => {
            return otherPlayer.score() - currentPlayer.score() < 0;
        });

        // Check if tileValue exists in the values of the last tiles of players
        if (competitors.some(player => player.lastTile()?.value === tileValue)) {
            // return 0; // This disables stealing entirely
            if (hasHighestScore && tileTable.tiles.length === 1) {
                return 1;
            } else {
                return 2;
            }
        }
        return 0;
    }

    if (minimalData === null || exactlyData === null) {
        return Array(6).fill({});
    }

    const allRolledDiceZero = rolledDice.every((dice) => dice === 0);

    const MultiplyProbabilities = (minim: ProbabilityRecord, exact: ProbabilityRecord): ProbabilityCalculation => {
        const result: ProbabilityCalculation = {};

        // Iterate through each tile value in the minim probabilities object.
        // Any value in minim is also present in exact
        for (const tileValueStr in minim) {
            // Parse the tile value string to a number
            const tileValue = parseInt(tileValueStr);

            // Get the tile worth
            const tile = allTiles.find(tile => tile.value === tileValue);
            const worth = tile ? tile.worth : 0;

            // Get the multiplier based on there the tile is located
            // 0 = absent, 1 = on the board, 2 = at a player
            const multiplier = getMultiplier(tileValue);

            // Get the potential loss
            const lastTile = currentPlayer.lastTile();
            const loss = lastTile ? lastTile.worth : 0;

            // If the tile value is present in the collection, add it to the result
            let sourceProbs = undefined;
            if (multiplier === 0) {
                // The tile is pickable nor stealable
                continue;
            } else if (multiplier === 1) {
                // The tile is pickable
                sourceProbs = minim;
            } else if (multiplier === 2) {
                // The tile is stealable
                sourceProbs = exact;
            }
            if (sourceProbs === undefined) {
                // This cant happen because the state is always 0, 1 or 2
                // But it makes type hinting happy
                continue;
            }
            const prob: number = sourceProbs[tileValue];
            if (prob === undefined) {
                // The tile value cannot be reached
                continue;
            }
            result[tileValue] = prob * (multiplier * worth + loss);
        }
        return result;
    };

    const newProbs: ProbabilityCalculations = {};
    for (let i = 0; i < currentCollection.length; i++) {
        // Only show for collectable dice
        if (!allRolledDiceZero && (rolledDice[i] === 0 || currentCollection[i] !== 0)) {
            continue;
        }
        const newCollection = [...currentCollection];
        newCollection[i] += rolledDice[i];
        newProbs[i] = MultiplyProbabilities(minimalData[newCollection.join("")], exactlyData[newCollection.join("")]) || {};
    }
    return newProbs;
};
